import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Alert, Badge } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { characters } from '../../services/api';

const CharacterForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    greeting: '',
    personality: '',
    scenario: '',
    example_dialogues: '',
    tags: [],
    avatar: null,
    reset_avatar: false
  });
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (id) {
      fetchCharacter();
    }
  }, [id]);

  const fetchCharacter = async () => {
    try {
      const response = await characters.get(id);
      const character = response.data;
      setFormData({
        name: character.name || '',
        title: character.title || '',
        greeting: character.greeting || '',
        personality: character.personality || '',
        scenario: character.scenario || '',
        example_dialogues: character.example_dialogues || '',
        tags: character.tags || [],
        avatar: null,
        reset_avatar: false
      });
    } catch (error) {
      setError('Failed to load character');
      console.error('Error fetching character:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    
    if (type === 'file') {
      setFormData(prev => ({
        ...prev,
        [name]: files[0] || null
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (newTag.trim() && !formData.tags.includes(newTag.trim())) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, newTag.trim()]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const formDataToSend = new FormData();
      
      Object.keys(formData).forEach(key => {
        if (key !== 'avatar' && key !== 'tags' && formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      });

      if (formData.avatar instanceof File) {
        formDataToSend.append('avatar', formData.avatar);
      }

      if (Array.isArray(formData.tags)) {
        formDataToSend.append('tags', JSON.stringify(formData.tags));
      }

      const response = id
        ? await characters.update(id, formDataToSend)
        : await characters.create(formDataToSend);

      setSuccess(id ? 'Character updated successfully!' : 'Character created successfully!');
      
      if (!id) {
        setFormData({
          name: '',
          title: '',
          greeting: '',
          personality: '',
          scenario: '',
          avatar: null,
          reset_avatar: false,
          tags: []
        });
      }
    } catch (error) {
      console.error('Error saving character:', error);
      setError(
        error.response?.data?.error || 
        error.response?.data?.message || 
        'Failed to save character'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="py-4">
      <h2>{id ? 'Edit Character' : 'Create New Character'}</h2>
      
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert variant="success" onClose={() => setSuccess('')} dismissible>
          {success}
        </Alert>
      )}
      
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            maxLength={30}
            placeholder="Character name"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            maxLength={100}
            placeholder="Character title or role"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Greeting</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="greeting"
            value={formData.greeting}
            onChange={handleChange}
            maxLength={2000}
            placeholder="How does your character introduce themselves?"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Personality</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="personality"
            value={formData.personality}
            onChange={handleChange}
            maxLength={2000}
            placeholder="Describe your character's personality"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Scenario</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="scenario"
            value={formData.scenario}
            onChange={handleChange}
            maxLength={1000}
            placeholder="Describe the scenario or setting"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Example Dialogues</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="example_dialogues"
            value={formData.example_dialogues}
            onChange={handleChange}
            maxLength={2000}
            placeholder="Add some example conversations"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Tags</Form.Label>
          <div className="d-flex gap-2 mb-2">
            <Form.Control
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Add a tag"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddTag(e);
                }
              }}
            />
            <Button 
              variant="outline-primary" 
              onClick={handleAddTag}
              style={{ minWidth: '100px' }}
            >
              Add Tag
            </Button>
          </div>
          <div className="d-flex gap-2 flex-wrap">
            {formData.tags.map((tag, index) => (
              <Badge 
                key={index} 
                bg="secondary" 
                className="p-2 cursor-pointer" 
                style={{ cursor: 'pointer' }}
                onClick={() => handleRemoveTag(tag)}
              >
                {tag} ×
              </Badge>
            ))}
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Avatar</Form.Label>
          <Form.Control
            type="file"
            name="avatar"
            onChange={handleChange}
            accept="image/*"
          />
        </Form.Group>

        {id && (
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Reset avatar to default"
              name="reset_avatar"
              checked={formData.reset_avatar}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                reset_avatar: e.target.checked,
                avatar: null
              }))}
            />
          </Form.Group>
        )}

        <div className="d-flex gap-2">
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save Character'}
          </Button>
          <Button variant="secondary" onClick={() => navigate('/characters')}>
            Back to Characters
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CharacterForm; 