import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AuthProvider } from './contexts/AuthContext';
import Navigation from './components/Layout/Navigation';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import CharacterList from './components/Characters/CharacterList';
import CharacterDetail from './components/Characters/CharacterDetail';
import CharacterForm from './components/Characters/CharacterForm';
import Chat from './components/Chat/Chat';
import RecentChats from './components/Chat/RecentChats';
import Home from './components/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  useEffect(() => {
    document.body.setAttribute('data-bs-theme', 'dark');
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Navigation />
        <Container fluid className="p-0">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/characters" element={<CharacterList />} />
            <Route path="/characters/:id" element={<CharacterDetail />} />
            <Route path="/characters/new" element={<CharacterForm />} />
            <Route path="/characters/:id/edit" element={<CharacterForm />} />
            <Route path="/characters/:characterId/chat" element={<Chat />} />
            <Route path="/chats/:chatId" element={<Chat />} />
            <Route path="/recent-chats" element={<RecentChats />} />
          </Routes>
        </Container>
      </Router>
    </AuthProvider>
  );
}

export default App;
