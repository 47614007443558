import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Form, Pagination, Alert } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { characters } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import LoginPromptModal from '../Modals/LoginPromptModal';

const CharacterList = () => {
  const [characterList, setCharacterList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [error, setError] = useState('');

  // Get current params from URL
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const currentSort = searchParams.get('sort') || 'newest';
  const currentTag = searchParams.get('tag') || null;

  useEffect(() => {
    fetchCharacters();
  }, [currentPage, currentSort, currentTag, searchQuery]);

  const fetchCharacters = async () => {
    setError('');
    try {
      const response = await characters.list({
        page: currentPage,
        sort: currentSort,
        limit: 20,
        tag: currentTag,
        q: searchQuery || undefined
      });
      
      setCharacterList(response.data.characters);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching characters:', error);
      setError(error.response?.data?.error || 'Failed to fetch characters');
    } finally {
      setLoading(false);
    }
  };

  const handleSortChange = (e) => {
    setSearchParams({ 
      sort: e.target.value, 
      page: '1',
      ...(currentTag && { tag: currentTag })
    });
  };

  const handlePageChange = (page) => {
    setSearchParams({ 
      sort: currentSort, 
      page: page.toString(),
      ...(currentTag && { tag: currentTag })
    });
  };

  const handleTagClick = (tag) => {
    const processedTag = tag.toLowerCase().replace(/\s+/g, '');
    setSearchParams({ 
      sort: currentSort, 
      page: '1', 
      tag: processedTag 
    });
  };

  // Clear tag filter
  const clearTagFilter = () => {
    setSearchParams({ 
      sort: currentSort, 
      page: '1'
    });
  };

  const renderPagination = () => {
    if (!pagination.totalPages) return null;

    let items = [];
    for (let i = 1; i <= pagination.totalPages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Pagination className="justify-content-center mt-4">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {items}
        <Pagination.Next
          disabled={currentPage === pagination.totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </Pagination>
    );
  };

  const handleCharacterClick = (e, characterId) => {
    if (!user) {
      e.preventDefault();
      setShowLoginModal(true);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    // Clear existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    
    // Set new timeout for debouncing
    const timeout = setTimeout(() => {
      setSearchParams({
        page: '1',
        ...(currentSort && { sort: currentSort }),
        ...(currentTag && { tag: currentTag }),
        ...(value && { q: value })
      });
    }, 300);
    
    setSearchTimeout(timeout);
  };

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <Container>
      {error && (
        <Alert variant="danger" className="mt-3 mb-3" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
      
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-3 mb-4">
        <h2>Characters</h2>
        <div className="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-center">
          <Form.Control
            type="search"
            placeholder="Search characters..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-100"
          />
          {currentTag && (
            <Button 
              variant="outline-secondary" 
              size="sm"
              onClick={clearTagFilter}
              className="text-truncate"
            >
              Clear Tag Filter: {currentTag} ×
            </Button>
          )}
          <Form.Select
            value={currentSort}
            onChange={handleSortChange}
            className="w-100 w-sm-auto"
          >
            <option value="newest">Newest First</option>
            <option value="oldest">Oldest First</option>
            <option value="name">Name A-Z</option>
            <option value="name_desc">Name Z-A</option>
            <option value="popular">Most Popular</option>
          </Form.Select>
          {user && (
            <div className="d-flex gap-2">
              <Link to="/recent-chats" className="w-100 w-sm-auto">
                <Button variant="secondary" className="w-100">Recent Chats</Button>
              </Link>
              <Link to="/characters/new" className="w-100 w-sm-auto">
                <Button variant="primary" className="w-100">Create New</Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <Row xs={2} sm={2} md={3} lg={4} xl={5} className="g-3">
        {characterList.map((character) => (
          <Col key={character.id}>
            <Link 
              to={`/characters/${character.id}/chat`} 
              className="text-decoration-none"
              onClick={(e) => handleCharacterClick(e, character.id)}
            >
              <Card className="character-card h-100">
                <Card.Body className="text-center d-flex flex-column">
                  <div className="character-avatar-container mx-auto mb-2">
                    {character.avatar_url ? (
                      <img
                        src={character.avatar_url}
                        alt={character.name}
                        className="character-avatar"
                      />
                    ) : (
                      <div className="character-avatar-placeholder">
                        {character.name.charAt(0)}
                      </div>
                    )}
                  </div>
                  <Card.Title className="mb-1 fs-6">
                    {character.name}
                  </Card.Title>
                  {character.title && (
                    <Card.Subtitle className="text-muted small mb-2">
                      {character.title}
                    </Card.Subtitle>
                  )}
                  <div className="text-muted small mb-2">
                    <div className="text-truncate">by {character.created_by_display_name || character.created_by_username}</div>
                    <div>{character.chat_count || 0} chats</div>
                  </div>
                  {character.tags && character.tags.length > 0 && (
                    <div className="d-flex flex-wrap gap-1 justify-content-center mt-auto">
                      {character.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="badge bg-secondary text-truncate"
                          style={{ cursor: 'pointer', maxWidth: '100%' }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleTagClick(tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>

      {renderPagination()}

      <LoginPromptModal 
        show={showLoginModal} 
        onHide={() => setShowLoginModal(false)} 
      />
    </Container>
  );
};

export default CharacterList; 