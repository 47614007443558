import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { chats } from '../../services/api';

const RecentChats = () => {
  const [recentChats, setRecentChats] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecentChats();
  }, []);

  const fetchRecentChats = async () => {
    try {
      const response = await chats.list();
      setRecentChats(response.data.chats);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
    }
  };

  const handleChatClick = (chatId) => {
    navigate(`/chats/${chatId}`);
  };

  return (
    <Container>
      <h2>Recent Chats</h2>
      <ListGroup>
        {recentChats.map(chat => (
          <ListGroup.Item key={chat.id} action onClick={() => handleChatClick(chat.id)}>
            <div className="d-flex align-items-center">
              <Image src={chat.character_avatar} roundedCircle width={40} height={40} className="me-3" />
              <div>
                <div>{chat.character_name}</div>
                <small className="text-muted">{chat.last_message}</small>
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default RecentChats; 