export const formatLocalTime = (utcTimestamp) => {
  if (!utcTimestamp) return '';
  
  // Parse the UTC timestamp
  const date = new Date(utcTimestamp.replace(' ', 'T') + 'Z');
  
  // Format the date to local time
  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

export const formatLocalDateTime = (utcTimestamp) => {
  if (!utcTimestamp) return '';
  
  // Parse the UTC timestamp
  const date = new Date(utcTimestamp.replace(' ', 'T') + 'Z');
  
  // Format the date to local date and time
  return date.toLocaleString([], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
}; 

