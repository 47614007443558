import axios from 'axios';

const API_URL =  'https://mikeoxlong.helioho.st/'//'http://localhost:4000';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor for adding auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const auth = {
  register: (formData) => api.post('/register', formData),
  login: (credentials) => api.post('/login', credentials),
  logout: () => api.post('/logout')
};

export const characters = {
  list: ({ page = 1, limit = 20, sort = 'newest', tag = null, q = null } = {}) => {
    const params = new URLSearchParams({
      page: page.toString(),
      limit: limit.toString(),
      sort
    });
    
    if (tag) {
      params.append('tag', tag);
    }
    
    if (q) {
      params.append('q', q);
    }
    
    return axios.get(`${API_URL}characters?${params.toString()}`);
  },
  get: (id) => api.get(`/characters/${id}`),
  create: (data) => 
    api.post('/characters', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  update: (id, data) => 
    api.put(`/characters/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  delete: (id) => api.delete(`/characters/${id}`)
};

const chats = {
  create: (characterId) => 
    api.post('/chats', { character_id: characterId }),
  
  sendMessage: (chatId, content) =>
    api.post(`/chats/${chatId}/messages`, { content }),
  
  getHistory: (chatId) =>
    api.get(`/chats/${chatId}`),
  
  list: () =>
    api.get('/chats'),

  editMessage: (chatId, messageId, content) =>
    api.put(`/chats/${chatId}/messages/${messageId}`, { content }),

  deleteMessage: (chatId, messageId) =>
    api.delete(`/chats/${chatId}/messages/${messageId}`),
};


// Add profile endpoint
const profile = {

  get: () => api.get('/profile'),
};

export default api;
export { chats, profile };
