import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Modal, Alert } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { characters, chats, profile } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { formatLocalTime } from '../../utils/dateUtils';

const EditMessageModal = ({ 
  show, 
  onHide, 
  message, 
  character, 
  userProfile, 
  editContent, 
  setEditContent, 
  onSubmit 
}) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Message</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {message && (
        <div className="message">
          <div className="message-meta">
            <span className="message-author">
              {message.role === 'assistant' ? character.name : userProfile.display_name}
            </span>
            <span className="text-muted">
              {formatLocalTime(message.timestamp)}
            </span>
          </div>
          
          <div className="message-input-container">
            <div className="message-avatar">
              {message.role === 'assistant' ? (
                character.avatar_url ? (
                  <img src={character.avatar_url} alt={character.name} />
                ) : (
                  <div className="avatar-placeholder">{character.name[0]}</div>
                )
              ) : (
                userProfile.avatar_url ? (
                  <img src={userProfile.avatar_url} alt={userProfile.display_name} />
                ) : (
                  <div className="avatar-placeholder">
                    {userProfile.display_name[0]}
                  </div>
                )
              )}
            </div>
            <Form.Control
              as="textarea"
              rows={3}
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
            />
          </div>
        </div>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" onClick={onSubmit}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
);

const Chat = () => {
  const { characterId, chatId } = useParams();
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const [character, setCharacter] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (authLoading) return;

    if (!user) {
      navigate('/login', { 
        state: { 
          from: chatId ? `/chats/${chatId}` : `/characters/${characterId}/chat` 
        } 
      });
      return;
    }
    
    if (chatId) {
      console.log('Loading chat history for:', chatId);
      loadChatHistory(chatId);
    } else if (characterId) {
      console.log('Initializing chat for character:', characterId);
      initializeChat();
    }
    fetchUserProfile();
  }, [characterId, chatId, user, authLoading]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchUserProfile = async () => {
    try {
      const response = await profile.get();
      setUserProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const initializeChat = async () => {
    try {
      const chatResponse = await chats.create(characterId);
      const chatData = await chatResponse.data;
      
      navigate(`/chats/${chatData.chat_id}`, { replace: true });
      
      setCharacter(chatData.character);
      setMessages([{
        role: 'assistant',
        content: chatData.first_message,
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error initializing chat:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCharacterData = async (characterId) => {
    try {
      const response = await characters.get(characterId);
      setCharacter({
        id: response.data.id,
        name: response.data.name,
        avatar_url: response.data.avatar_url
      });
    } catch (error) {
      console.error('Error fetching character:', error);
      navigate('/characters');
    }
  };

  const loadChatHistory = async (chatId) => {
    try {
      const response = await chats.getHistory(chatId);
      const { chat, messages: chatMessages } = response.data;
      
      if (!character) {
        await fetchCharacterData(chat.character_id);
      }

      setMessages(chatMessages.map(msg => ({
        id: msg.id,
        role: msg.role === 'character' ? 'assistant' : 'user',
        content: msg.content,
        timestamp: msg.created_at
      })));
    } catch (error) {
      console.error('Error loading chat history:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || isTyping) return;

    const messageContent = newMessage;
    setNewMessage('');
    
    const userMessage = {
        role: 'user',
        content: messageContent,
        timestamp: new Date().toISOString()
    };

    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    try {
        const response = await chats.sendMessage(chatId || character.chat_id, messageContent);
        const { messages: chatMessages } = response.data;
        
        setMessages(chatMessages.map(msg => ({
            id: msg.id,
            role: msg.role === 'character' ? 'assistant' : 'user',
            content: msg.content,
            timestamp: msg.created_at
        })));
    } catch (error) {
        setMessages(prev => prev.slice(0, -1));
        
        if (error.response?.data?.messageDeleted) {
            setNewMessage(error.response.data.deletedMessage);
            
            setAlert({
                type: 'danger',
                message: 'Failed to send message. The message has been restored to the input field. Please try again.',
                dismissible: true
            });
        } else {
            setAlert({
                type: 'danger',
                message: 'An error occurred while sending the message.',
                dismissible: true
            });
        }
    } finally {
        setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isTyping) {
        handleSubmit(e);
      }
    }
  };

  const formatMessage = (text) => {
    const parts = text.split(/(\*[^*]+\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return (
          <span key={index} className="emphasized-text">
            {part.slice(1, -1)}
          </span>
        );
      }
      return part;
    });
  };

  const handleEditMessage = (message) => {
    console.log('Message to edit:', message);
    setEditingMessage(message);
    setEditContent(message.content);
    setShowEditModal(true);
  };

  const handleEditSubmit = async () => {
    if (!editingMessage || !editContent.trim()) return;
    
    try {
      console.log('Editing message:', editingMessage);
      console.log('Chat ID:', chatId);
      console.log('Message ID:', editingMessage.id);
      
      const response = await chats.editMessage(
        chatId, 
        editingMessage.id,
        editContent
      );
      
      if (response.data.messages) {
        setMessages(response.data.messages.map(msg => ({
          id: msg.id,
          role: msg.role === 'character' ? 'assistant' : 'user',
          content: msg.content,
          timestamp: msg.created_at,
          is_edited: msg.is_edited
        })));
      }
      
      setShowEditModal(false);
      setEditingMessage(null);
      setEditContent('');
    } catch (error) {
      console.error('Error editing message:', error);
      console.error('Error details:', error.response?.data);
    }
  };

  const handleDeleteMessage = (message) => {
    setMessageToDelete(message);
    setShowDeleteModal(true);
  };

  const confirmDeleteMessage = async () => {
    if (!messageToDelete || !messageToDelete.id) {
      console.error('Invalid message ID for deletion:', messageToDelete);
      return;
    }

    try {
      console.log('Deleting message:', messageToDelete);
      console.log('Chat ID:', chatId);
      console.log('Message ID:', messageToDelete.id);

      const response = await chats.deleteMessage(chatId, messageToDelete.id);
      
      // Use the updated list of messages from the API response
      if (response.data.messages) {
        setMessages(response.data.messages.map(msg => ({
          id: msg.id,
          role: msg.role === 'character' ? 'assistant' : 'user',
          content: msg.content,
          timestamp: msg.created_at,
          is_edited: msg.is_edited
        })));
      }

      // Close the modal and reset the messageToDelete
      setShowDeleteModal(false);
      setMessageToDelete(null);
    } catch (error) {
      console.error('Error deleting message:', error);
      console.error('Error details:', error.response?.data);
    }
  };

  const DeleteMessageModal = () => (
    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this message?</p>
        <div className="message-preview">
          {messageToDelete?.content}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={confirmDeleteMessage}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (loading || !character || !userProfile || !messages) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (!character.name) {
    return <div className="text-center mt-5">Error: Invalid character data</div>;
  }

  return (
    <Container fluid className="chat-container p-0">
      {alert && (
        <div className="chat-alert">
          <Alert 
            variant={alert.type} 
            dismissible={alert.dismissible}
            onClose={() => setAlert(null)}
          >
            {alert.message}
          </Alert>
        </div>
      )}
      <div className="chat-header">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <div className="character-avatar-small">
              {character.avatar_url ? (
                <img src={character.avatar_url} alt={character.name} />
              ) : (
                <div className="avatar-placeholder">{character.name[0]}</div>
              )}
            </div>
            <div className="character-info">
              <h4>{character.name}</h4>
              {character.title && <small className="text-muted">{character.title}</small>}
            </div>
          </div>
          
          {user && user.id === character.created_by && (
            <Link to={`/characters/${character.id}/edit`}>
              <Button variant="outline-light" size="sm">
                <i className="fas fa-edit me-1"></i>
                Edit Character
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="messages-container">
        {messages.map((message, index) => (
          <div key={message.id || index} className={`message ${message.role}`}>
            <div className="message-avatar">
              {message.role === 'assistant' ? (
                character.avatar_url ? (
                  <img src={character.avatar_url} alt={character.name} />
                ) : (
                  <div className="avatar-placeholder">{character.name[0]}</div>
                )
              ) : (
                userProfile.avatar_url ? (
                  <img src={userProfile.avatar_url} alt={userProfile.display_name} />
                ) : (
                  <div className="avatar-placeholder">
                    {userProfile.display_name[0]}
                  </div>
                )
              )}
            </div>
            <div className="message-content">
              <div className="message-header">
                <span className="message-author">
                  {message.role === 'assistant' ? character.name : userProfile.display_name}
                </span>
                <span className="message-time">
                  {formatLocalTime(message.timestamp)}
                  {message.is_edited && <span className="edited-indicator">(edited)</span>}
                </span>
                <div className="message-actions">
                  <button 
                    className="btn btn-link btn-sm"
                    onClick={() => handleEditMessage(message)}
                  >
                    Edit
                  </button>
                  {message.role === 'user' && (
                    <button 
                      className="btn btn-link btn-sm text-danger"
                      onClick={() => handleDeleteMessage(message)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
              <div className="message-text">{formatMessage(message.content)}</div>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="message assistant">
            <div className="message-avatar">
              {character.avatar_url ? (
                <img src={character.avatar_url} alt={character.name} />
              ) : (
                <div className="avatar-placeholder">{character.name[0]}</div>
              )}
            </div>
            <div className="message-content">
              <div className="message-header">
                <span className="message-author">{character.name}</span>
              </div>
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <Form onSubmit={handleSubmit} className="chat-input">
        <Form.Control
          as="textarea"
          rows={1}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
          style={{ resize: 'none' }}
        />
        <Button 
          type="submit" 
          disabled={isTyping || !newMessage.trim()}
        >
          {isTyping ? 'Typing...' : 'Send'}
        </Button>
      </Form>
      <EditMessageModal 
        show={showEditModal} 
        onHide={() => setShowEditModal(false)} 
        message={editingMessage} 
        character={character} 
        userProfile={userProfile} 
        editContent={editContent} 
        setEditContent={setEditContent} 
        onSubmit={handleEditSubmit} 
      />
      <DeleteMessageModal />
    </Container>
  );
};

export default Chat; 