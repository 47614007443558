import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Alert } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { characters } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

const CharacterDetail = () => {
  const [character, setCharacter] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    fetchCharacter();
  }, [id]);

  const fetchCharacter = async () => {
    try {
      const response = await characters.get(id);
      setCharacter(response.data);
    } catch (error) {
      setError('Failed to load character');
      console.error('Error fetching character:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this character?')) {
      try {
        await characters.delete(id);
        navigate('/characters');
      } catch (error) {
        setError('Failed to delete character');
        console.error('Error deleting character:', error);
      }
    }
  };

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!character) {
    return <Alert variant="info">Character not found</Alert>;
  }

  return (
    <Container className="py-4">
      <Card>
        {character.avatar_url && (
          <Card.Img
            variant="top"
            src={character.avatar_url}
            alt={character.name}
            style={{ maxHeight: '400px', objectFit: 'cover' }}
          />
        )}
        <Card.Body>
          <Card.Title className="h2">{character.name}</Card.Title>
          {character.title && (
            <Card.Subtitle className="mb-3 text-muted">
              {character.title}
            </Card.Subtitle>
          )}

          {character.greeting && (
            <Card.Text className="border-bottom pb-3">
              <strong>Greeting:</strong>
              <br />
              {character.greeting}
            </Card.Text>
          )}

          {character.personality && (
            <Card.Text className="border-bottom pb-3">
              <strong>Personality:</strong>
              <br />
              {character.personality}
            </Card.Text>
          )}

          {character.scenario && (
            <Card.Text className="border-bottom pb-3">
              <strong>Scenario:</strong>
              <br />
              {character.scenario}
            </Card.Text>
          )}

          {character.example_dialogues && (
            <Card.Text className="border-bottom pb-3">
              <strong>Example Dialogues:</strong>
              <br />
              {character.example_dialogues}
            </Card.Text>
          )}

          {character.tags && character.tags.length > 0 && (
            <Card.Text>
              <strong>Tags:</strong>
              <br />
              {character.tags.join(', ')}
            </Card.Text>
          )}

          <div className="mt-4">
            <Link to="/characters">
              <Button variant="secondary" className="me-2">
                Back to List
              </Button>
            </Link>
            {user && user.id === character.created_by && (
              <>
                <Link to={`/characters/${id}/edit`}>
                  <Button variant="primary" className="me-2">
                    Edit
                  </Button>
                </Link>
                <Button variant="danger" onClick={handleDelete}>
                  Delete
                </Button>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CharacterDetail; 