import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Home = () => {
  const { user } = useAuth();

  return (
    <div className="home-container">
      <div className="hero-section text-center py-5">
        <Container>
          <h1 className="display-4 mb-4">Welcome to CharChats-AI</h1>
          <p className="lead mb-4">
            Create and chat with unique AI characters. Bring your imagination to life through engaging conversations.
          </p>
          {!user && (
            <div className="mb-4">
              <Link to="/register">
                <Button variant="primary" size="lg" className="me-3">
                  Get Started
                </Button>
              </Link>
              <Link to="/login">
                <Button variant="outline-primary" size="lg">
                  Login
                </Button>
              </Link>
            </div>
          )}
        </Container>
      </div>

      <Container className="py-5">
        <h2 className="text-center mb-5">Why Choose CharChats-AI?</h2>
        <Row className="g-4">
          <Col md={4}>
            <Card className="h-100">
              <Card.Body className="text-center">
                <div className="feature-icon mb-3">
                  <i className="fas fa-robot fa-3x"></i>
                </div>
                <Card.Title>Create Custom Characters</Card.Title>
                <Card.Text>
                  Design unique AI personalities with detailed backgrounds, traits, and behaviors.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100">
              <Card.Body className="text-center">
                <div className="feature-icon mb-3">
                  <i className="fas fa-comments fa-3x"></i>
                </div>
                <Card.Title>Natural Conversations</Card.Title>
                <Card.Text>
                  Engage in meaningful dialogues with AI characters that remember context and maintain personality.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100">
              <Card.Body className="text-center">
                <div className="feature-icon mb-3">
                  <i className="fas fa-share-alt fa-3x"></i>
                </div>
                <Card.Title>Share & Explore</Card.Title>
                <Card.Text>
                  Share your characters with the community and discover characters created by others.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="cta-section text-center py-5 bg-dark">
        <Container>
          <h2 className="mb-4">Ready to Start Chatting?</h2>
          <Link to={user ? "/characters" : "/register"}>
            <Button variant="primary" size="lg">
              {user ? "Browse Characters" : "Create Your Account"}
            </Button>
          </Link>
        </Container>
      </div>
    </div>
  );
};

export default Home;
