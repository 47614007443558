import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LoginPromptModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Login Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You need to be logged in to chat with characters. Please login or create an account to continue.</p>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/login">
          <Button variant="primary">Login</Button>
        </Link>
        <Link to="/register">
          <Button variant="success">Create Account</Button>
        </Link>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginPromptModal;